/* EUI theme styles */
@import "@elastic/eui/dist/eui_theme_light.css";

/* Vendor global styles */
@import "./vendor/bootstrap-grid.css";

/* Global Styles */
body,
html {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
}
.header-font {
  font-family: Poppins, sans-serif;
}

/* Overriding styles */
.euiFieldText:focus {
  background-image: none;
  box-shadow: inset 0 0 0 1px #07c;
}
.euiBody-hasPortalContent {
  position: initial !important;
}
.euiFormLabel.euiFormLabel-isFocused {
  color: #1a1c21;
}
.euiPopover__arrow::before {
  border-color: transparent transparent #cce4f5 transparent !important;
}
.euiTourFooter {
  border: none !important;
  background: none !important;
}
.customFlyout .euiButtonIcon {
  display: none;
}
.euiModal__closeIcon {
  display: none !important;
}
.euiTableRow:hover {
  background-color: #e6f9f7;
}
.customFlyout {
  z-index: 3001 !important;
}
.btn-text {
  font-size: 16px !important;
  margin-right: 1rem;
}
label {
  font-size: 16px !important;
}
.euiFieldText {
  font-size: 16px !important;
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
  max-width: 428px !important;
}
/* input {
  font-size: 16px !important;
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
} */

.euiFormControlLayoutIcons .euiIcon[data-icon-type="warning"] {
  display: none;
}

.euiRadio .euiRadio__input ~ .euiRadio__label {
  z-index: 0;
}

.debt-icon {
  align-self: flex-end;
  padding-bottom: .75rem;
  svg {
    width: 20px !important;
    fill: #1a1c21
  }
  .euiButtonIcon {
    display: block !important;
  }
}

.euiIcon.css-h17o4q-euiIcon-m-danger {
  display: none;
}
.euiFormControlLayout {
  height: auto;
  max-width: 428px;
}
.btn-spacing {
  margin-right: 1rem;
}
.euiComboBox .euiComboBox__inputWrap {
  font-size: 16px !important;
  background-color: #fff !important;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1) !important;
  background-image: none !important;
}
.euiSuperSelectControl-isInvalid {
  border-color: 1px solid #bd271e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 39, 30, 1);
  background-image: none;
}
.euiComboBox.euiComboBox-isInvalid .euiComboBox__inputWrap {
  border: 1px solid #bd271e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 39, 30, 1);
}
.euiComboBox .euiComboBox-isOpen .euiComboBox_inputWrap {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 1) !important;
  background-image: none !important;
}
.euiFormHelpText {
  font-size: 16px !important;
  line-height: 1.5;
}
.input-size-child {
  flex: 1 1 auto;
}
.euiFormControlLayout--group {
  background-color: #5e646f !important;
}
.euiFormControlLayout--group:focus {
  border-color: #07c;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 1);
}

/* MOBILE */
@media (max-width: 445px) {
  .continue-button {
    margin-left: 0 !important;
    color: #006BB8;
  }
}
@media (max-width: 365px) {
  .continue-button {
    margin-top: 1rem;
  }
}
@media (min-width: 576px) {
  .euiFormRow,
  .euiFormControlLayout,
  input {
    max-width: 428px !important;
  }

  .euiFormRow,
  .euiFormControlLayout {
    max-width: 428px !important;
  }

  .euiComboBox {
    max-width: 428px !important;
  }

  .euiComboBox .euiComboBox__inputWrap {
    max-width: 428px !important;
  }

  .css-3n67oa-euiPopover-EuiInputPopover {
    max-inline-size: 428px !important;
  }

  .euiSuperSelectControl {
    max-width: 428px !important;
  }

  .input-size-child input {
    width: 192px;
  }
}
.euiSuperSelectControl.euiSuperSelect--isOpen__button {
  background-color: white;
  background-image: none;
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 1);
}
.euiSuperSelectControl:focus {
  background-image: none;
  box-shadow: inset 0 0 0 1px rgba(94, 100, 111, 1);
}
.euiSuperSelect__item:hover {
  text-decoration: none;
  background-color: rgba(0, 109, 228, 0.1);
}
.euiSuperSelect__item {
  font-size: 16px;
}
.euiSuperSelectControl {
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
  font-size: 16px;
}
.euiFormControlLayout
  .euiFormControlLayout__childrenWrapper
  .euiFieldText:focus {
  border-color: #07c;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 1);
}
.euiFieldText:invalid {
  border-color: #bd271e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 39, 30, 1);
  background-image: none;
  background-size: none;
}
button.euiButton.css-vdoabi-euiButtonDisplay-m-defaultMinWidth-isDisabled-fill-disabled {
  opacity: 0.4;
  color: white;
  background: #0077cc;
  font-size: 16px;
}

input:focus,
textarea:focus,
button:focus,
select:focus {
  outline: none !important;
}

option:focus {
  outline: none !important;
}

/* .euiFormErrorText {
  display: none;
} */
.input-size-small {
  max-width: 206px !important;
  background-color: #fff;
}

.input-size {
  max-width: 428px !important;
  font-family: "Inter", sans-serif;
}

.euiFormLabel {
  line-height: 24px;
}

.euiFormLabel.euiFormLabel-isInvalid {
  color: inherit;
}
.euiFormRow + .euiFormRow,
.euiFormRow + .euiButton {
  margin-top: 0;
}
.euiButton {
  font-size: 16px !important;
}
.euiButton .euiComboBoxPill--plainText {
  font-size: 16px;
}
.euiComboBoxPill--plainText {
  font-size: 16px;
}
.react-datepicker {
  display: none;
}
button[aria-selected="true"] {
  text-decoration: none !important;
}
button.euiFilterSelectItem {
  &:hover {
    background-color: rgba(0, 109, 228, 0.1);
    text-decoration: none;
  }
}
.euiContextMenuItem {
  border-top: 1px solid #f1f4fa;
}
span.euiComboBoxOption__content {
  font-size: 16px;
  line-height: 24px;
}
span.euiBadge.euiComboBoxOption__enterBadge.css-1d0tqsn-euiBadge-hollow {
  display: none;
}
.flyout-text {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #1a1c21;
}
.subscriptionCard {
  border: 1px solid #5e646f !important;
  padding: 16px;
  width: 202px;
  height: 132px;
  h3 {
    font-size: 22px;
    line-height: 20px;
    font-family: "Inter", sans-serif;
  }
  .price {
    font-size: 27px;
    font-family: "Inter", sans-serif;
    color: #343741;
  }
  .sub-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .plan-text {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      color: #343741;
    }
  }
}

.annual-sub {
  position: relative;
}

.subscriptionCard.recommended::after {
  content: "RECOMMENDED";
  position: absolute;
  top: -13px;
  right: 40px;
  background: #0077cc;
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  padding: 4px 12px;
  border-radius: 14px;
  z-index: 1;
}

.euiPanel.euiPanel--subdued.euiPanel--paddingMedium.euiSplitPanel__inner.css-1q2pk3l-euiPanel-none-m-subdued-isClickable-euiSplitPanelInner {
  background: none;
  padding-top: 8px;
  padding-left: 0;
}

.euiPanel.euiPanel--primary.euiPanel--paddingMedium.euiSplitPanel__inner.css-1wbcvob-euiPanel-none-m-primary-isClickable-euiSplitPanelInner {
  background: none;
  padding-top: 8px;
  padding-left: 0;
}

label.euiCheckableCard__label.css-a2fh8y-euiCheckableCard__label {
  padding-left: 0px;
  padding-top: 8px;
  padding-right: 0;
}

.euiPanel.euiPanel--plain.euiSplitPanel.euiCheckableCard.subscriptionCard.css-fbc5mb-euiPanel-m-plain-hasBorder-euiSplitPanelOuter-row-euiCheckableCard-isChecked {
  margin: 0;
  background-color: #e6f1fa;
  border: 1px solid #0077cc !important;
  box-shadow: 0 0 0 3px #0077cc;
}
.euiPanel.euiPanel--transparent.euiPanel--paddingMedium.euiSplitPanel__inner.css-1lwt1x1-euiPanel-grow-none-m-transparent-euiSplitPanelInner {
  padding-left: 0;
}

.billing-summary {
  max-width: 444px;
  border: none;
  border-radius: 4px;
  padding: 16px;
  background-color: #f1f4fa !important;
  box-shadow: none !important;
  font-family: "Inter", sans-serif;
  color: #1a1c21;
}

.billingSummary .euiHorizontalRule {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Login-Registration Page */

.half {
  width: 50%;
  position: fixed;
  top: 0;
  overflow: auto;
  padding: 1rem;
  .term-condition {
    max-width: 428px;
    width: 100%;
    font-family: "Inter", sans-serif;
    color: #343741;
    a.term-link {
      color: #343741 !important;
      text-decoration: underline;
    }
  }
}
.info-container {
  left: 0;
  background-color: #e6f1fa;
  display: flex;
  align-items: center;
  justify-content: center;
  .info-content {
    text-align: center;
    ul {
      list-style-type: none;
      text-align: left;
      max-width: 600px;
      width: 100%;
    }
    li {
      display: flex;
      align-items: flex-start;
      color: #1a1c21;
      font-weight: 400;
      font-size: 22px;
      font-family: "Inter", sans-serif;
      line-height: 30px;
    }
    li::before {
      content: url("./assets/svgs/check.svg");
      margin-right: 10px;
      margin-top: 5px;
    }
  }
}
.field-container {
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
  .password-input {
    input {
      background-color: #fff;
    }
    .euiFieldPassword {
      padding-left: 12px !important;
    }
    .euiFormControlLayoutIcons {
      display: none;
    }
    .euiFormControlLayout--group .euiButtonIcon:not(:focus),
    .euiFormControlLayout--group
      [class*="euiButton"]:focus:not(:focus-visible) {
      background-color: #fbfcfd;
    }
    .euiButtonIcon {
      height: initial !important;
      padding-left: 0;
      padding-right: 12px;
    }
    .euiFormControlLayout--group:focus-within {
      background-color: #07c !important;
      box-shadow: 0 0 0 1px rgba(0, 119, 204, 1);
    }
    .euiFieldPassword:focus {
      background-color: white !important;
      background-image: none !important;
    }
  }
}
.field-content {
  width: 100%;
  max-width: 444px;
  .desc-text {
    color: #1a1c21;
    font-size: 16px;
    font-family: "Inter", sans-serif;
  }
  .password-input {
    .euiFormRow__labelWrapper {
      display: block;
    }
    .euiFormLabel {
      display: flex;
      justify-content: space-between;
    }
    input {
      height: 40px;
    }
  }
  .pass-valid {
    margin-top: 4px;
    font-size: 16px;
    max-width: 428px;
    width: 100%;
    font-family: "Inter", sans-serif;
    color: #69707d;
    .euiIcon.css-h17o4q-euiIcon-m-danger {
      display: inline-block;
    }
    svg {
      margin-top: 2px;
    }
    .pass-align {
      display: flex;
      gap: 8px;
    }
  }
  .euiFieldText {
    max-width: 444px !important;
  }
  @media (min-width: 576px) {
    .euiFormRow,
    .euiFormControlLayout,
    input {
      max-width: 444px !important;
    }

    .euiFormRow,
    .euiFormControlLayout {
      max-width: 444px !important;
    }

    .euiComboBox {
      max-width: 444px !important;
    }

    .euiComboBox .euiComboBox__inputWrap {
      max-width: 444px !important;
    }

    .css-3n67oa-euiPopover-EuiInputPopover {
      max-inline-size: 444px !important;
    }

    .euiSuperSelectControl {
      max-width: 444px !important;
    }
  }
}
.mobile-logo {
  display: none;
}
.logo {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 99999;
}
.login-button {
  width: 444px;
}
.login-main {
  .half {
    height: 100%;
  }
  .field-container {
    justify-content: center;
    padding-top: 0;
    margin-top: 0;
  }
}
.add-text {
  color: #1a1c21 !important;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  max-width: 428px;
  width: 100%;
  a {
    color: #1a1c21 !important;
    font-weight: 400 !important;
    text-decoration: underline;
  }
}
.new-paywall {
  position: absolute;
  top: 0;
  z-index: 99999;
  @media screen and (max-width: 992px){
    .logo {
      padding-left: 0 !important;
    }
  }
}
.password-input {
  .euiFormControlLayout--group .euiButtonIcon:not(:focus),
  .euiFormControlLayout--group [class*="euiButton"]:focus:not(:focus-visible) {
    border: none;
  }
  .euiFieldPassword:invalid {
    box-shadow: none;
    background-image: none;
  }
}
.euiComboBox__input {
  box-shadow: none;
}
.pass-link {
  font-weight: 400 !important;
}
.secondary-header {
  font-size: 23.6px !important;
}
.invalid .euiFormControlLayout.euiFormControlLayout--group {
  border-color: #bd271e;
  outline: none;
  box-shadow: 0 0 0 2px #bd271e;
  background-color: #bd271e !important;
  box-shadow: 0 0 0 1px #bd271e;
}
.email-error {
  max-width: 428px;
  width: 100%;
}
.curriculum {
  .field-container {
    justify-content: initial !important;
    .field-content {
      max-width: 445px;
    }
  }
  .header-mobile {
    font-size: 30px;
  }
  .term-condition {
    max-width: 428px;
    width: 100%;
    font-family: "Inter", sans-serif;
    color: #343741;
    a.term-link {
      color: #343741 !important;
      text-decoration: underline;
    }
  }
  .white-side {
    padding-top: 55px;
  }
  @media (max-width: 576px) {
    .euiFormRow {
      max-width: 428px;
    }
    .euiSuperSelectControl {
      max-width: 428px;
    }
    .euiPopover {
      max-inline-size: 428px;
    }
  }
}
.paywall {
  /* justify-content: initial !important; */
  height: 100%;
}
.header-mobile-lg {
  font-size: 30px !important;
}
.paywall-info {
  position: absolute;
  z-index: 9999;
  height: 100%;
}
.paywall-field {
  position: absolute;
  z-index: 9999;
  .logo {
    padding: 0;
  }
  @media screen and (max-width: 576px){
    .field-content {
      margin-top: 0 !important;
    }
  }
}
.paywall-logo {
  position: absolute;
  z-index: 9999;
}

@media (max-width: 992px) {
  .paywall {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .billing-summary {
    display: none !important;
  }

  .half,
  .info-container,
  .field-container {
    width: 100%;
    position: relative;
  }

  .info-container {
    display: none;
  }
  .mobile-logo {
    display: block;
  }
  .desktop-logo {
    display: none;
  }
  .field-container {
    padding-top: 50px;
    .feat-list-mobile {
      ul {
        list-style-type: none;
        max-width: 600px;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      li {
        display: flex;
        align-items: flex-start;
        color: #1a1c21;
        font-weight: 400;
        font-size: 16px;
        font-family: "Inter", sans-serif;
        line-height: 22px;
        margin-bottom: 5px;
      }
      li::before {
        content: url("./assets/svgs/mobile_check.svg");
        margin-right: 10px;
        margin-top: 2px;
      }
    }
  }
  .login-main {
    .field-container {
      /* justify-content: initial !important; */
    }
    .field-content {
      margin-top: 80px;
      text-align: center;
      .login-title {
        text-align: left;
      }
    }
    .forgot-password {
      text-align: left;
    }
  }
  .logo {
    padding-top: 1rem;
    padding-left: 1rem;
    position: initial;
  }
}
@media screen and (max-width: 576px) {
  .field-container {
    align-items: flex-start;
    .field-content {
      margin-top: 0;
    }
  }
  .euiFormRow,
  .euiFormControlLayout {
    max-width: 428px !important;
  }
}
@media screen and (max-width: 500px) {
  .subscriptionCard {
    width: 163px;
    height: 144px;
    .sub-content {
      gap: 12px;
    }
  }
  .subscriptionCard.recommended::after {
    right: 25px;
  }
}

.new-container {
  display: flex;
  min-height: 100vh; /* Ensures at least full viewport height */
  width: 100%;
}

.blue-side,
.white-side {
  flex: 1;
  display: flex;
  align-items: center; 
  justify-content: center;
  position: relative;
  min-height: 100vh;
}

.blue-side {
  color: white;
  align-items: flex-start;
}

.white-side {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 160px;
  justify-content: flex-start;
}

.new-logo {
  position: absolute;
  top: 52px;
  left: 60px;
  width: 161px;
  height: 39px;
}

.blue-content {
  width: 100%;
  padding-top: 171px; /* 52px logo top + 39px logo height + 120px gap */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.account-form {
  width: 100%;
  max-width: 444px;
  display: flex;
  flex-direction: column;
}

.form-header {
  width: 100%; 
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .blue-side {
    display: none;
  }

  .white-side {
    flex: 1;
    padding-top: 55px !important;
  }

  .header-font-reg {
    line-height: 28px !important;
  }
}

@media (max-width: 576px) {
  .white-side {
    padding-top: 32px !important;
    .form-header {
      margin-bottom: 0px;
    }
  }
}
